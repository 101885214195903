import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Button,
    IconButton,
    Toolbar,
    makeStyles
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import InputIcon from '@material-ui/icons/Input';

const useStyles = makeStyles(({
    root: {
        //borderBottom: "1px solid #ccc",
        //background: "#ccc"
    },
    toolbar: {
        height: 64
    }
}));

const TopBar = ({ className, ...rest }) => {
    const classes = useStyles();
    
    return (
    <AppBar
        className={clsx(classes.root, className)}
        elevation={1}
        //color="primary"
        {...rest}
    >
        <Toolbar className={classes.toolbar} >   
            <RouterLink to="/">
                <IconButton color="inherit" style={{color:"white"}}>
                    <HomeOutlinedIcon />
                </IconButton>
            </RouterLink>
        </Toolbar>
    </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string
};

export default TopBar;

