import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ThemeProvider } from '@material-ui/core';
import theme from 'theme';

export default function DefalutButton(props){
    return(
    <Button  color="primary" variant="contained">{props.name}</Button>    
    )
}