import React from 'react';
import  { Navigate } from  'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout'
import MainLayout from 'layouts/MainLayout';
import TagCreateView from 'views/create/TagCreateView'
;import LoginView from 'views/auth/LoginView';

const routes = [
    {
        path: 'app',
        element: <DashboardLayout/>,
        children: [
            { path: 'tagcreate', element:<TagCreateView />},
            { path: '/', element:<Navigate to="/app/tagCreate" />}
        ]
    },
    {
        path :'/',
        element: <MainLayout />,
        children: [
            { path: 'login', element:<LoginView /> },
            { path: '/', element: <Navigate to="/login" /> },
            { path: '*', element: <Navigate to="/404" /> },
        ],
    }
];

export default routes;