import React, {useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Page from 'components/Page'

const useStyles = makeStyles((theme) =>({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
}))

const TagCreateView = () =>{
    const maxRow = 30;
    const minRow = 1;

    const classes = useStyles();
    
    const [generateTag ,setGenerateTag] = useState("ここにタグが出力されます");

    const [textDatas, setTextDatas] = useState([]);
    const [rows, setRows] = useState(0);

    const items = [
        {url:"", text:"1-1" },{url:"", text:"1-2" },{url:"", text:"1-3" }
    ]

    const generate = () =>{
        setGenerateTag("TAG\ntag");
    }

    const changeRows = (e) =>{
        let row = e.target.value
        if(minRow > row) row = minRow; 
        if(maxRow < row) row = maxRow;
        setRows(row);
        createUrlTextField(row);
    }

    const createUrlTextField = (row) => {
        var setItems = textDatas
        var len = setItems.length
        var addCount = row - len
        if(addCount  > 0){
            for(var i = 0; i < addCount ; i++){
                var num = i + len  + 1
                var it = [ {url:"", text: num + "-1" },{url:"", text: num + "-2" },{url:"", text: num + "-3" }]
                setItems.push(it)
            }
        }else{
            var subCount = len - row
            for(var i = 0; i < subCount; i++){
                setItems.pop();
            }
        }
        setTextDatas(
            setItems
        )
    }

    const changeUrlTextField = (row, column,e) => {
        console.log(row,column)
        console.log(textDatas[row][column])
        let setItems = textDatas
        setItems[row][column].url = e.target.value
        //console.log(e.target.value)
        let data = Object.assign([],setItems) //配列を更新する際は新しくオブジェクトを作り直す必要がある
        setTextDatas(
            data
        )
        console.log(textDatas)
    }


    
    return(
    <Page
        className={classes.root}
        title="タグ生成"
    >
        <Container maxWidth="lg">
            <Box mt={3}>
            <Card>
                <CardHeader title="入力" subheader=""/>
                <Divider/>
                <CardContent>
                    <Typography color="textPrimary" gutterBottom variant="h5">画像URL</Typography>
                    <TextField label="行数" type="number" InputLabelProps={{shrink: true,}} variant="outlined" value={rows} onChange={changeRows}/>
                    <Grid container spacing={6} wrap="wrap">
                        <Grid className={classes.item} item md={4} sm={6} xs={12}>
                            {   
                                textDatas.map((text,index) =>(
                                    <TextField  id="" label="" helperText={text[0].text} value={text[0].url} onChange={(e)=>{changeUrlTextField(index,0,e)}} />
                                ))
                            }
                        </Grid>
                        <Grid className={classes.item} item md={4} sm={6} xs={12}>
                            {textDatas.map((text,index) =>(
                                <TextField  id="" label=""  helperText={text[1].text} value={text[1].url} onChange={(e)=>{changeUrlTextField(index,1,e)}}/>
                            ))}
                        </Grid>
                        <Grid className={classes.item} item md={4} sm={6} xs={12}>
                            {textDatas.map((text,index) =>(
                                <TextField  id="" label=""  helperText={text[2].text} value={text[2].url} onChange={(e)=>{changeUrlTextField(index,2,e)}}/>
                            ))}
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button color="primary" variant="contained">
                        プレビュー
                    </Button>
                </Box>
            </Card>
            </Box>

            <Box mt={3}>  
                <Button  color="primary" variant="contained" fullWidth onClick={generate}>生成</Button>    
            </Box>

            <Box mt={3}>
            <Card>
                <CardHeader title="出力" subheader=""/>
                <Divider/>
                <CardContent>
                    <TextField id="" label={"出力タグ"} variant="outlined" fullWidth value={generateTag} multiline={true} ></TextField>
                </CardContent>
            </Card>
            </Box>
        </Container>
        
    </Page>
    )
}

export default TagCreateView;