import React from 'react';
import { Box, Card, Container,TextField, makeStyles, Button ,IconButton} from '@material-ui/core';
import Page from 'components/Page'
import DefaultButton from 'components/buttons/DefaultButton'
import InputIcon from '@material-ui/icons/Input';

const useStyles = makeStyles((theme) =>({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}))

const LoginView = () =>{
    const classes = useStyles();
    
    return(
    <Page
        className={classes.root}
        title="ログイン"
    >
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
        >
            <Container maxWidth="sm">
                <div>
                    <h1>LOGIN</h1>
                    <TextField fullWidth label="Email Address" variant="outlined" margin="normal" />
                    <DefaultButton name="LOGIN"></DefaultButton>
                </div>
            </Container>

        </Box>

    </Page>

    )
}

export default LoginView;