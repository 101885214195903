import { createMuiTheme, colors } from '@material-ui/core';
import { green ,teal} from '@material-ui/core/colors';
import shadows from 'theme/shadows';
import typography from 'theme/typography';

const theme = createMuiTheme({
    palette: {
        background: {
            dark: '#F4F6F8',
            default: colors.common.white,
            paper: colors.common.white,
            
        },
        primary: {
            main: colors.teal[500]
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600]
        }
    },
    shadows,
    typography
});

export default theme;