import React, { useEffect } from 'react';
import {Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Drawer,
    Divider,
    Hidden,
    List,
    makeStyles
} from '@material-ui/core';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import NavItem from './NavItem'

const items = [
    {
        href: '/app/tagcreate',
        icon: HomeOutlinedIcon,
        title: 'タグ生成'
    },
]

const useStyles = makeStyles(() =>({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
}))

const NavBar = ({onMobileClose, openMobile}) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() =>{
        if(openMobile && onMobileClose){
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Divider/>
            <Box p={2}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    );


    return(
        <>
            {/*<Hidden lgUp>
                <Drawer
                    anchor={{ paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>*/}
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes= {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}

NavBar.defaultProps = {
    onMobileClose: () =>{},
    openMobile: false
}

export default NavBar;